/* eslint-disable */
<template>
  <section id="hero">
    <v-img
      :min-height="minHeight"
      :src="require('@/assets/light.jpg')"
      class="white--text"
      gradient="to bottom, rgba(11, 28, 61, .9), rgba(0,0,0,.4)"
    >
      <v-container class="fill-height px-4 py-12">
        <v-responsive
          class="d-flex align-center mx-auto"
          height="100%"
          max-width="700"
          width="100%"
        >
          <base-subheading
            weight="regular"
            title="Connecting You to a World of Possibilities"
          />

          <base-heading
            size="text-h3"
            title="WELCOME TO AWM Telco"
            weight="medium"
          />

          <base-body>
            Let your business be enhanced by our solutions, get in touch with
            our team today!
          </base-body>

          <div
            :class="
              $vuetify.breakpoint.smAndDown
                ? 'flex-column align-start'
                : 'align-center'
            "
            class="d-flex flex-wrap"
          >
            <base-btn @click="$vuetify.goTo(target, options)">
              Discover More
            </base-btn>
          </div>
        </v-responsive>
      </v-container>
    </v-img>
  </section>
</template>

<script>
import * as easings from "vuetify/lib/services/goto/easing-patterns"

export default {
  name: "SectionHero",

  provide: {
    theme: { isDark: true },
  },

  data() {
    return {
      type: "number",
      number: 1000,
      duration: 500,
      offset: 0,
      easing: "easeInOutCubic",
      easings: Object.keys(easings),
    }
  },

  computed: {
    minHeight() {
      const height = this.$vuetify.breakpoint.mdAndUp ? "100vh" : "50vh"

      return `calc(${height} - ${this.$vuetify.application.top}px)`
    },
    target() {
      const value = this[this.type]
      if (!isNaN(value)) return Number(value)
      else return value
    },
    options() {
      return {
        duration: this.duration,
        offset: this.offset,
        easing: this.easing,
      }
    },
  },
}
</script>
